<template>
  <div>
    <el-page-header @back="dynamic" content="信息发布" style="margin-bottom: 15px;">
    </el-page-header>
    <el-form ref="form" :model="peoForm" label-width="80px" size="mini" label-position="top" class="pt-0">
      <el-form-item>
        <el-input v-model="peoForm.peoName" placeholder="姓名" style="width: 15%"></el-input>
        <el-input v-model="peoForm.peoPhoto" disabled placeholder="图片地址" style="width: 30%;margin-left: 20px"></el-input>
        <el-button type="primary" size="mini" @click="dialogFormVisible = true" style="margin-left: 20px">上传</el-button>
        <el-dialog title="上传头像" :visible.sync="dialogFormVisible" >
          <el-upload
              class="upload-demo"
              action="/api/people/upLoadPeoImg"
              :before-remove="beforeRemove"
              :on-success="upSucess"
              :on-remove="hanldRemove"
              multiple
              :limit="3"
              style="width: 50%">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dialogFormVisible = false;">确 定</el-button>
          </div>
        </el-dialog>
      </el-form-item>
      <el-form-item style="height: 368px">
        <quill-editor ref="text" v-model="peoForm.peoInfomation" class="myQuillEditor" :options="editorOption" />
      </el-form-item>
      <el-form-item>
        <el-select v-model="peoForm.peoClass" placeholder="模块" clearable style="width: 100px;margin-right: 20px">
          <el-option label="学术委员会" :value="1"></el-option>
          <el-option label="中心艺术家" :value="2"></el-option>
          <el-option label="中心领导" :value="3"></el-option>
          <el-option label="秘书处" :value="4"></el-option>
        </el-select>
        <el-radio-group v-model="peoForm.isIndex">
          <el-radio-button :label="0">模块</el-radio-button>
          <el-radio-button :label="1">首页</el-radio-button>
        </el-radio-group>
        <el-select v-model="peoForm.tagString" multiple placeholder="标签" @change="$forceUpdate()" style="margin-left: 20px; width: 400px">
          <el-option
              v-for="item in tagData"
              :key="item.tagId"
              :label="item.tagName"
              :value="item.tagId">
          </el-option>
        </el-select>
        <el-button type="primary" size="mini" @click="insertPeo();" style="margin-left: 20px">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { quillEditor } from 'vue-quill-editor'
export default {
  name: "peoEditer",
  components: {
    quillEditor
  },
  data() {
    return {
      editorOption: {},
      peoForm: {
        peoId: '',
        peoName: '',
        isIndex: '1',
        peoClass: '',
        peoPhoto: '',
        peoInfomation: '',
        tagString: []
      },
      formLabelWidth: '120px',
      peopleData: [],
      tagData: [],
      dialogFormVisible: false
    }
  },
  mounted() {
    this.queryTagList()
    if (this.$route.query.peoId !== undefined) {
      this.getPeoById(this.$route.query.peoId)
    }
  },
  methods: {
    dynamic() {
      this.$router.push("people")
    },
    getPeoById(peoId){
      this.$http({
        method: 'get',
        url: '/api/people/getPeoById/' + peoId,
      }).then((res) =>{
        this.peoForm = res.data.data
        var tags = res.data.data.tag
        var tagList = []
        for (var i = 0; i < tags.length; i++) {
          tagList.push(tags[i].tagId)
        }
        this.peoForm.tagString = tagList
      })
    },
    insertPeo(){
      console.log(this.peoForm)
      this.$confirm('是否新增?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var peoMethod = 'post';
        var peoUrl = '/api/people/insertPeo';
        if (this.peoForm.peoId !== '') {
          peoMethod = 'put';
          peoUrl = '/api/people/updatePeo';
        }
        this.$http({
          method: peoMethod,
          url: peoUrl,
          data: this.peoForm
        }).then((res) =>{
          this.$message({
            type: 'info',
            message: res.data.msg
          })
          this.peoForm = {
            peoId: '',
            peoName: '',
            peiPhoto: '',
            isIndex: '1',
            peoClass: '',
            peoInfomation: '',
            tagString: []
          }
          this.$router.push("people")
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消新增'
        })
      })
    },
    queryTagList() {
      this.$http({
        method: 'get',
        url: '/api/tag/getTagList',
      }).then((res) =>{
        this.tagData = res.data.data
      })
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    upSucess(file){
      this.peoForm.peoPhoto = file
    },
    hanldRemove(file) {
      console.log(file.name)
      this.peoForm.peoPhoto = ""
    }
  }
}
</script>

<style scoped>
.myQuillEditor {
  height: 300px;
}
</style>